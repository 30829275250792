import type {
  Options as HighchartsOptions,
  SeriesPieOptions,
  PointOptionsObject,
} from 'highcharts'
import type { ComputedRef, Ref } from 'vue'
import type { ISearchPropertyLeasePortfolioRequest } from '@portfolio'
import type { IDashboardResultGeographicExposure } from '~/composables/useAPI/useApiDashboard'
import { CHART_COLORS } from '../components/contstants'
import { createDashboardReportURI } from '../utils'
import { DashboardQuery } from '@dashboard'

export const useGeographicExposureChart = (
  result: Ref<IDashboardResultGeographicExposure>,
  type: MaybeRefOrGetter<
    | DashboardQuery.GEOGRAPHIC_EXPOSURE
    | DashboardQuery.GEOGRAPHIC_EXPOSURE_BY_RENT
  >,
  templateId: ComputedRef<string | undefined>,
) => {
  const router = useRouter()
  const companyId = getCurrentDivisionId()

  const typeText = {
    [DashboardQuery.GEOGRAPHIC_EXPOSURE]: 'area',
    [DashboardQuery.GEOGRAPHIC_EXPOSURE_BY_RENT]: 'rent',
  }

  return computed<HighchartsOptions>(() => {
    const data: SeriesPieOptions['data'] = Object.entries(
      result.value,
    ).map<PointOptionsObject>(([name, { link, value: y }], idx) => ({
      name: fullStateToAbbreviation(name),
      y,
      link,
      color: CHART_COLORS[idx % CHART_COLORS.length],
      events: {
        legendItemClick(e) {
          const options = e.target.options
          if ('link' in options) {
            redirectToReport(
              options.link as ISearchPropertyLeasePortfolioRequest,
              //TODO: add template add this.templateId as ...
            )

            useAnalytics({
              name: 'Chart click',
              showDivision: true,
              data: {
                Origin: `Geographic exposure (by ${typeText[toValue(type)]}) - legend`,
              },
            })
          }
          return false
        },
      },
    }))

    return {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        // plotBackgroundColor: null,
        // plotBorderWidth: null,
        plotShadow: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      xAxis: {
        crosshair: true,
        labels: {
          style: {
            color: '#BFBFBF',
          },
        },
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        gridLineColor: '#444',
        tickInterval: 40,
        gridLineWidth: 1,
        labels: {
          style: {
            color: '#BFBFBF',
          },
        },
        title: {
          text: '',
          style: {
            color: '#BFBFBF',
          },
        },
      },
      tooltip: {
        formatter: function () {
          return `<b>${abbreviationToFullState(this.key)}</b><br/><b>
            ${new Intl.NumberFormat().format(this.y * 100)}%
            </b>`
        },
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          // marker: false,
          // lineWidth: 2,
          showInLegend: true,
          dataLabels: {
            enabled: false,
          },
          point: {
            events: {
              click() {
                if ('link' in this) {
                  redirectToReport(
                    this.link as ISearchPropertyLeasePortfolioRequest,
                    templateId.value,
                  )
                  useAnalytics({
                    name: 'Chart click',
                    showDivision: true,
                    data: {
                      Origin: `Geographic exposure (by ${typeText[toValue(type)]}) - Chart Point`,
                    },
                  })
                }
              },
            },
          },
        },
      },
      legend: {
        itemHoverStyle: {
          color: '#D8FC59',
        },
        itemStyle: {
          color: 'white',
        },
      },

      series: [
        {
          borderWidth: 0,
          borderRadius: 0,
          // colors: data.results.map((item) => item.color),
          data,
          type: 'pie',
        } satisfies SeriesPieOptions,
      ],
    } satisfies HighchartsOptions
  })

  function redirectToReport(
    link: ISearchPropertyLeasePortfolioRequest | undefined,
    templateId?: string,
  ) {
    const path = createDashboardReportURI(companyId, link, templateId)
    if (path) {
      router.push(path)
    }
  }
}
